import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Layout, Listing, Wrapper, SliceZone, Title, SEO, Header } from '../components'
import Categories from '../components/Listing/Categories'
import website from '../../config/website'

const Hero = styled.header`
  background-color: ${props => props.theme.colors.greyLight};
  padding-top: 1rem;
  padding-bottom: 4rem;
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`

const PostWrapper = Wrapper.withComponent('main')

const Post = ({ data: { wordpressPost, posts }, location }) => {
  return (
    <Layout customSEO>
      <SEO
        title={`${wordpressPost.title} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={wordpressPost.excerpt}
        node={wordpressPost}
        article
      />
      <Hero>
        <Wrapper>
          <Header />
          <Headline>
            {wordpressPost.date} — {wordpressPost.categories && <Categories categories={wordpressPost.categories} />}
          </Headline>
          <h1>{wordpressPost.title}</h1>
        </Wrapper>
      </Hero>
      <PostWrapper id={website.skipNavId}>
        <div dangerouslySetInnerHTML={{__html: wordpressPost.content}} />
        <Title style={{ marginTop: '4rem' }}>Recent posts</Title>
        <Listing posts={posts.edges} />
      </PostWrapper>
    </Layout>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    wordpressPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    wordpressPost(slug: {eq: $slug}) {
      id
      slug
      title
      categories {
        path
        name
        slug
      }
      content
      date(formatString: "DD.MM.YYYY")
      excerpt
    }
    posts: allWordpressPost(limit: 2, sort: {fields: date, order: DESC}, filter: {slug: {ne: $slug}}) {
      edges {
        node {
          title
          slug
          path
          categories {
            name
            path
            slug
          }
          date(formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`
